import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StyleProvider } from "@ant-design/cssinjs";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { HashRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <StyleProvider layer>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HashRouter basename="/">
            <App />
          </HashRouter>
        </PersistGate>
      </Provider>
    </StyleProvider>
  </React.StrictMode>,
);

reportWebVitals();
