import axios from "axios";
import { config, grantType } from "../config/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { authActions } from "../store/reducers/userReducer";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import React from "react";

export const api = axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Content-Type": "application/json",
    "X-Api-Key": config.secretKey,
  },
});

/**
 * Serialize javascript object for sending to api
 * @param {Object} data
 * @returns {String}
 */
export function serialize(data: any) {
  return Object.keys(data)
    .map((keyName) => {
      return `${encodeURIComponent(keyName)}=${data[keyName] ? encodeURIComponent(data[keyName]) : ""}`;
    })
    .join("&");
}

const useMakeApiRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = useSelector((state: RootState) => state.auth.authUser);
  const [isApiLoading, setLoading] = React.useState(false);
  const { idToken, accessToken } = authUser ?? {};

  // Note: Don't Try to call from useAuth it will get into recursive recursion
  const logout = () => {
    dispatch(authActions.logout()); // Clear the authUser object from the Redux store
    navigate("/login-new");
    message.success("Logged out Successfully!");
  };

  /**
   * Method for making ajax calls to the site's api
   * @param {String} endpoint - the endpoint url
   * @param {String} method api methid POST | GET | DELETE
   * @param {Object|String} [params] - key:value pairs of the data to be sent to server
   * @param {Object|String} [data] - key:value pairs of the data to be sent to server
   * @param {String} contentType - header contentType of request
   * @returns {Promise}
   */

  async function makeApiRequest(
    endpoint: string,
    method: string,
    params?: Object,
    data: any | string = null,
    contentType?: string,
    isEpresURL?: boolean,
  ) {
    const request: any = {
      baseURL: isEpresURL ? config.epresApiUrl : config.apiUrl,
      method,
      url: endpoint,
      params: params,
      data: data ? (endpoint.includes("oauth") ? serialize(data) : data) : "",
    };

    if (endpoint.includes("oauth")) {
      request.headers = {
        "Content-Type": contentType || "application/x-www-form-urlencoded",
        Authorization: `Basic ${
          data.grant_type === grantType.CLIENT_CREDENTIALS
            ? config.clientCredentialInternal
            : config.clientCredentialMobile
        }`,
      };
    } else {
      if (accessToken) {
        request.headers = {
          Authorization: `Bearer ${accessToken}`,
          "X-API-Key": isEpresURL ? config.epresSecretKey : config.secretKey,
        };
        request.headers["x-id-token"] = idToken;
      } else {
        request.headers = {
          "X-API-Key": isEpresURL ? config.epresSecretKey : config.secretKey,
        };
      }

      if (contentType) {
        request.headers["Content-Type"] = contentType;
      } else if (endpoint.includes("convert-to-pdf")) {
        request.headers["Content-Type"] = "application/json";
        request.headers.Accept = "application/pdf";
        request.responseType = "blob";
      }
    }

    try {
      const response: any = await api(request);

      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 202
      ) {
        return { ...response.data, status: response.status };
      } else {
        const error: any = new Error(response.statusText);

        error.response = response;
        throw error;
      }
    } catch (err: any) {
      if (err.response && err.response.status === 400) {
        return err.response.data;
      }
      if (err.response && err.response.status === 403) {
        return err.response.data;
      }
      if (err.response && err.response.status === 401) {
        if (err.response.data.error === "Access denied") logout();
        else return err.response.data;
      } else {
        const error: any = new Error(err.message);

        error.response = err;
        throw error;
      }
    }
  }

  /**
   * Method for making ajax calls to the site's api
   * @param {String} apiUrl - the api url
   * @param {String} endpoint - the endpoint url
   * @param {Object|string} [data] - key:value pairs of the data to be sent to server
   * @returns {Promise}
   */
  async function makeExternalRequest(
    apiUrl: string,
    endpoint: string,
    data = null,
  ) {
    const url = `${apiUrl}${endpoint}${data ? `?${serialize(data)}` : ""}`;
    const response = await fetch(url);

    if (response.ok) {
      const data = await response.json();

      return data;
    } else {
      const error: any = new Error(response.statusText);

      error.response = response;
      throw error;
    }
  }

  const handleApiRequest = async (
    apiCall: Function,
    successCallback: Function,
    errorMessage = "Something went wrong!",
    showLoading: boolean,
  ) => {
    if (showLoading) {
      setLoading(true);
    }
    try {
      const response = await apiCall();
      if (response.error ?? response.errors) {
        message.error(response.error ?? response.message);
      } else {
        successCallback(response);
      }
    } catch (error: any) {
      message.error(
        error?.response?.data?.message ||
          error?.response?.response?.data?.error ||
          errorMessage,
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    makeApiRequest,
    makeExternalRequest,
    handleApiRequest,
    isApiLoading,
  };
};

export default useMakeApiRequest;
