import Resizer from "react-image-file-resizer";
import {
  Button,
  Checkbox,
  Col,
  Form,
  message,
  Row,
  Tabs,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import DocUpdateIcon from "../../assets/DocUpdateIcon.png";
import { ReactComponent as SignUpDocIcon } from "../../assets/signupdocIcon.svg";
import { useForm } from "../../utils/hooks/useForm";
import PhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import FloatInput from "../../components/FloatInput/floatInput";
import "./signup.modules.css";
import React from "react";
import SignatureCanvas from "react-signature-canvas";
import { Link, useNavigate } from "react-router-dom";
import { dataURLtoFile } from "../../utils/methods/util-methods";
import { useAuth } from "../../utils/hooks/useAuth";
import {
  allowNumOnly,
  confirmPasswordValidation,
  emailPattenValidation,
  passwordPatternValidation,
  phoneNumberLength,
  policyCheckboxValidation,
  useAsyncValidate,
  validateDeaPattern,
  validateExactLength,
  validateMaxLength,
  validateMaxValue,
  validateNPIPattern,
} from "../../utils/methods/form-validations";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import TabPane from "antd/es/tabs/TabPane";
import { TActiveSignupForm } from "../../types/global.types";
import { StoreValue } from "antd/es/form/interface";

const SignupV1 = () => {
  const navigate = useNavigate();
  const { form } = useForm();
  const [networkform] = Form.useForm();
  const { signup, isLoading, findUserByNPI, isApiLoading, smsSignUp } =
    useAuth(form);
  const [currentForm, setCurrentForm] =
    React.useState<TActiveSignupForm>("network-signup");
  const { validateDeaNumberFormat } = useAsyncValidate();
  const sigRef = React.useRef<SignatureCanvas | null>(null);
  const [passportID, setPassportID] = React.useState<any>(null);
  const [tabKey, setTabKey] = React.useState(3);
  const [onUploadError, setOnUploadError] = React.useState<string>("");
  const [onSignatureError, setOnSignatureError] = React.useState<string>("");
  const [countryData, setCountryData] = React.useState<CountryData | undefined>(
    undefined,
  );
  const [showPlaceholder, setShowPlaceholder] = React.useState<boolean>(true);
  const [isPhoneHasError, setIsPhoneHasError] = React.useState<boolean>(false);

  const clearSignature = () => {
    if (sigRef?.current) {
      sigRef?.current?.clear();
      setOnSignatureError("Signature is required!");
    }
  };

  const onFinish = async (values: any) => {
    try {
      await signup(
        {
          ...values,
          email: values.signupEmail,
          password: values.signupPassword,
          phoneNumber: values.phoneNumber,
          signatureFile: values.signatureFile,
          passportID,
        },
        setShowPlaceholder,
      );
    } catch (error: unknown) {
      message.error(`${error}`);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    const checkPhoneNumber = form.getFieldError("phoneNumber")?.length > 0;
    setIsPhoneHasError(checkPhoneNumber);
    if (
      errorInfo.values.privacyPolicyAgreement &&
      errorInfo.errorFields.length &&
      tabKey === 2
    ) {
      message.error(
        "Please complete all required fields in the previous steps before proceeding.",
      );
    }
  };

  const validatePhone = (value: string, countryData: CountryData) => {
    const phoneValue =
      form.getFieldValue("phoneNumber") ||
      networkform.getFieldValue("network_phoneNumber");
    setShowPlaceholder(phoneValue.length < 2);
    setCountryData(countryData);
  };

  const props: UploadProps = {
    name: "file",
    listType: "picture",
    maxCount: 1,

    beforeUpload: async (file: RcFile) => {
      const isSupportedFormat = [
        "image/png",
        "image/jpeg",
        "image/jpg",
      ].includes(file.type);
      if (!isSupportedFormat) {
        message.error("You can only upload JPG, JPEG, or PNG files!");
        return Upload.LIST_IGNORE;
      }

      if (file.size > 5 * 1024 * 1024) {
        message.error("Image must be smaller than 5MB!");
        return Upload.LIST_IGNORE;
      }

      // Calculate 70% of the original image dimensions
      const image = new Image();
      image.src = URL.createObjectURL(file);

      const resizedFile: RcFile = await new Promise((resolve) => {
        image.onload = () => {
          const width = Math.floor(image.width * 0.3);
          const height = Math.floor(image.height * 0.3);

          Resizer.imageFileResizer(
            file,
            width,
            height,
            file.type.split("/")[1].toUpperCase(),
            80, // Keeping quality at 100 to prioritize size reduction by dimensions
            0,
            (uri: any) => {
              // Convert URI back to a file
              const byteString = atob(uri.split(",")[1]);
              const arrayBuffer = new ArrayBuffer(byteString.length);
              const intArray = new Uint8Array(arrayBuffer);

              for (let i = 0; i < byteString.length; i++) {
                intArray[i] = byteString.charCodeAt(i);
              }

              const blob = new Blob([arrayBuffer], { type: file.type });
              const resizedFile = new File([blob], file.name, {
                type: file.type,
              });
              resolve(resizedFile as RcFile);
            },
            "base64",
          );
        };
      });

      // Trigger file change with resized file
      props.onChange?.({ file: resizedFile, fileList: [resizedFile] });
      return false; // Prevent auto-uploading
    },

    onPreview: async (file: any) => {
      const url = file.url || URL.createObjectURL(file.originFileObj);
      window.open(url, "_blank");
    },

    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      if (status !== "uploading") {
        setPassportID(info.file);
        setOnUploadError("");
      }
    },

    onRemove: () => {
      return true;
    },

    showUploadList: true,
  };

  const handleFinish = (values: any) => {
    if (tabKey === 1) {
      setTabKey((prevValue) => prevValue + 1);
    } else {
      if (!passportID || passportID.status === "removed") {
        setOnUploadError("Driver Liscensee,Passport is Required!");
      }
      const dataURL = sigRef?.current?.toDataURL("image/png");
      const signatureFile = dataURLtoFile(dataURL ?? "", "signature.png");
      if (sigRef?.current?.isEmpty()) {
        setOnSignatureError("Signature is required!");
      }
      if (passportID.status !== "removed" && !sigRef?.current?.isEmpty()) {
        onFinish({ ...values, signatureFile });
      } else return;
    }
  };

  const handleFinishNetwork = (values: StoreValue) => {
    if (tabKey === 3) {
      findUserByNPI({ ...values }, setTabKey, networkform);
    } else if (tabKey === 4) {
      smsSignUp({ ...values }, setTabKey, setShowPlaceholder, networkform);
    }
  };

  const onNetworkFinishFailed = (_: StoreValue) => {
    const checkPhoneNumber =
      networkform.getFieldError("network_phoneNumber")?.length > 0;
    setIsPhoneHasError(checkPhoneNumber);
  };

  return (
    <div className="my-2 flex flex-col justify-center items-center px-6 py-4 w-full max-w-screen-2xl mx-auto">
      <img
        src={DocUpdateIcon}
        alt="docupdate"
        className="w-full max-w-48 object-contain mix-blend-multiply lg:mr-auto lg:ml-12"
      />

      <div className="flex justify-center items-center w-full lg:justify-between lg:pr-4 lg:pl-8">
        <div className="hidden lg:flex lg:flex-col lg:gap-y-7 lg:justify-center lg:items-center lg:mb-auto lg:ml-2 lg:mt-12">
          <div className="flex flex-col gap-y-4 justify-center">
            <Typography.Text className="font-urbanist font-semibold text-3xl">
              Create Account
            </Typography.Text>
            <Typography.Text className="font-urbanist font-medium text-lg text-[#050505]">
              Already have an account?
              <span
                className="font-urbanist text-[#7E5AE2] cursor-pointer font-bold"
                onClick={() => navigate("/login-new")}
              >
                {" "}
                Sign In Now
              </span>
            </Typography.Text>
            <div className="mt-6 ml-12 w-full h-full max-h-[318px]">
              <SignUpDocIcon className="object-cover w-[75%] h-full" />
            </div>
          </div>
        </div>
        <Tabs
          defaultActiveKey={"network-signup"}
          onChange={(activeKey) => {
            setCurrentForm(activeKey as TActiveSignupForm);
            setTabKey(activeKey === "network-signup" ? 3 : 1);
            form.resetFields();
            networkform.resetFields();
          }}
          className="mt-6 w-full max-w-[470px] signup-form-tabs"
          type="card"
        >
          {/* User Signup Tab */}
          <TabPane tab="Docupdate Network" key="network-signup">
            <Form
              form={networkform}
              name="network-signup"
              onFinish={handleFinishNetwork}
              onFinishFailed={onNetworkFinishFailed}
              className="mb-6 pt-2 flex flex-col bg-custom-gradient w-full max-w-[470px] justify-center items-center rounded-xl mobile-m:rounded-3xl mobile-m:px-2"
            >
              <div className="flex flex-col justify-center items-center w-full max-w-[380px]">
                <div className="w-full flex justify-between items-center">
                  {tabKey === 4 && (
                    <Button
                      className="bg-transparent border-none text-[#A8BDC1] rounded-[42px] p-4 hover:bg-[#e8f3ff] hover:text-[#7e5aeb]"
                      icon={<ArrowLeftOutlined />}
                      onClick={() => setTabKey(3)}
                    />
                  )}
                  <Typography.Text
                    className={`font-urbanist text-center text-3xl text-[#0F0F1C] w-full font-semibold ${tabKey === 3 ? "ml-8" : "mr-8"}`}
                  >
                    Sign Up
                  </Typography.Text>
                  {tabKey === 3 && (
                    <Button
                      className="bg-transparent border-none text-[#A8BDC1] rounded-[42px] p-4 hover:bg-[#fefefe] hover:text-[#7e5aeb]"
                      icon={<ArrowRightOutlined />}
                      onClick={() => setTabKey(4)}
                    />
                  )}
                </div>
                <Typography.Text className="m-4 font-urbanist text-center leading-4 text-[12px] w-full text-normal text-[#5d6769] mobile-m:text-sm">
                  Welcome to Docupdate. Please confirm your information
                </Typography.Text>

                <Typography.Text className="m-4 mt-0 max-w-[85%] font-urbanist text-justify leading-[1] text-[12px] w-full text-normal text-[#5d6769] mobile-m:text-sm mobile-m:max-w-[90%]">
                  Stay ahead with tailored updates from Docupdate, bringing you
                  the latest advancements in medications, indications, and
                  services in your field.
                </Typography.Text>

                {/* Tab container with absolute positioning */}
                <div
                  className={`relative w-full ${tabKey === 3 ? "min-h-[150px]" : "min-h-[515px]"} overflow-hidden`}
                >
                  <div
                    className={`absolute inset-0 transition-transform duration-700 ease-in-out transform ${
                      tabKey === 3 ? "translate-x-0" : "-translate-x-full"
                    }`}
                  >
                    <Row
                      gutter={[12, tabKey === 1 ? 16 : 8]}
                      className="px-4 xs:px-0 lg:!gap-y-3"
                      wrap
                      align={"middle"}
                      justify={"center"}
                    >
                      {/* First Tab Fields */}
                      <Col span={24}>
                        <Form.Item
                          name="network_npiNumber"
                          rules={[
                            {
                              required: !!(tabKey === 3),
                              message: "*NPI is Required",
                            },
                            validateNPIPattern(),
                            validateExactLength("NPI", 10),
                            validateMaxValue("NPI", 2147483647),
                          ]}
                          validateFirst
                        >
                          <FloatInput
                            name={"network_npiNumber"}
                            type="text"
                            placeholder={"NPI *"}
                            maxLength={10}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              allowNumOnly(e, networkform, "network_npiNumber")
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} className="relative md:mb-1">
                        <span
                          className={`absolute top-1/2 pl-[4.5rem] ${form.isFieldTouched("network_phoneNumber") || isPhoneHasError ? "-translate-y-[75%] md:-translate-y-[55%] lg:-translate-y-[61%]" : "-translate-y-[50%]"}  text-[#aaa] pointer-events-none ${showPlaceholder ? "z-10" : "-z-10"} text-sm`}
                        >
                          Mobile Phone Number*
                        </span>
                        <Form.Item
                          name="network_phoneNumber"
                          rules={[
                            {
                              required: !!(tabKey === 3),
                              message: "*Mobile Number is Required",
                            },
                            phoneNumberLength(countryData),
                          ]}
                          validateFirst
                        >
                          <PhoneInput
                            country={"us"}
                            disableDropdown
                            onlyCountries={["us"]}
                            countryCodeEditable={false}
                            onChange={validatePhone}
                            enableLongNumbers={
                              (countryData as CountryData)?.format.split(".")
                                .length -
                                1 <
                              15
                                ? false
                                : true
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>

                  <div
                    className={`absolute inset-0 transition-transform duration-700 ease-in-out transform ${
                      tabKey === 4 ? "translate-x-0" : "translate-x-full"
                    }`}
                  >
                    <Row className="px-4" gutter={8}>
                      {/* Second Tab Content */}
                      <Col span={12} className="p-0">
                        <Form.Item
                          name={"network_firstName"}
                          rules={[
                            {
                              required: !!(tabKey === 4),
                              message: "*First Name is Required",
                            },
                          ]}
                        >
                          <FloatInput
                            name={"network_firstName"}
                            placeholder={"First Name *"}
                            required
                            type="text"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="p-0 mb-3">
                        <Form.Item
                          name={"network_lastName"}
                          rules={[
                            {
                              required: !!(tabKey === 4),
                              message: "*Last Name is Required",
                            },
                          ]}
                        >
                          <FloatInput
                            name={"network_lastName"}
                            type="text"
                            placeholder={"Last Name *"}
                            required
                          />
                        </Form.Item>
                      </Col>
                      <div className="mt-4 flex justify-center items-center mx-auto">
                        <Form.Item
                          name={"privacyPolicyAgreement"}
                          valuePropName="checked"
                          rules={[policyCheckboxValidation(tabKey)]}
                          className="consent-form"
                        >
                          <Checkbox className="custom-checkbox font-urbanist text-justify text-xs font-normal text-[#5d6769] leading-tight mobile-m:max-w-[285px] mobile-lg:text-[14px] mobile-lg:max-w-[320px] xs:text-[15px] xs:max-w-[400px]">
                            By submitting this form and signing up for texts,
                            you consent to receive text messages from Docupdate
                            at the number provided, including message sent by
                            autodialer. Consent is not condition of purchase.
                            Msg & data rates may apply. Msg frequency varies
                            <span className="font-bold">&#46;</span> Unsubscribe
                            at any time by replying STOP or clicking the
                            unsubscribe link (where available). I read and agree
                            with the Docupdate
                            <Link
                              to={"https://www.docupdate.io/ccpa"}
                              target="_blank"
                              className="text-[#4169c4]"
                            >
                              {" "}
                              CCPA Policy,{" "}
                              <Link
                                to={"https://www.docupdate.io/privacy-policy"}
                                target="_blank"
                                className="text-[#4169c4]"
                              >
                                Privacy Policy
                              </Link>
                              ,{" "}
                            </Link>
                            and{" "}
                            <Link
                              to={"https://www.docupdate.io/tos"}
                              target="_blank"
                              className="text-[#4169c4]"
                            >
                              Terms of Service
                            </Link>
                            .
                            <Typography.Text className="mt-2 block font-urbanist text-xs font-normal text-[#5d6769] leading-tight tracking-tight mobile-m:max-w-[265px] mobile-lg:text-[14px] mobile-lg:max-w-[290px] xs:text-[15px] xs:max-w-[400px]">
                              By clicking Sign Up below I agree to permit
                              Docupdate, directly or by third parties acting on
                              its behalf, to send informational and marketing
                              messages - including texts and calls made using an
                              automatic telephone dialing system, AI generative,
                              pre-recorded or artificial voice messages -
                              related to Docupdate’s services or pharmaceutical
                              products (e.g., information on samples). Accepting
                              this consent is not required to obtain any good or
                              service. I can change my preferences by contacting{" "}
                              <Link
                                className="text-[#4169c4]"
                                to={"mailto:support@docupdate.io"}
                              >
                                support@docupdate.io
                              </Link>
                            </Typography.Text>
                          </Checkbox>
                        </Form.Item>
                      </div>
                    </Row>
                  </div>
                </div>
                <Col span={24} className="text-center lg:hidden">
                  <Typography.Text className="font-urbanist font-semibold text-sm text-[#050505]">
                    Already have an account?
                    <span
                      className="text-[#7E5AE2] cursor-pointer font-bold"
                      onClick={() => navigate("/login-new")}
                    >
                      {" "}
                      Sign In Now
                    </span>
                  </Typography.Text>
                </Col>
                <Col
                  span={24}
                  className="my-3 mb-6 px-4 flex justify-center items-center w-full"
                >
                  <Button
                    htmlType="submit"
                    className="bg-[#7E5AE2] text-white border-none w-full font-semibold rounded-md text-sm max-w-[644px] disabled:opacity-45"
                    loading={isApiLoading}
                    iconPosition="end"
                  >
                    {tabKey === 3 ? "NEXT" : "SIGN UP"}
                  </Button>
                </Col>
              </div>
            </Form>
          </TabPane>
          <TabPane tab="Docupdate Prescribing App" key={"prescriber-signup"}>
            <Form
              form={form}
              name="signup"
              onFinish={handleFinish}
              onFieldsChange={(changedFields) => {
                if (changedFields[0]?.name[0] === "signupPassword") {
                  form.validateFields(["confirmPassword"]);
                }
              }}
              onFinishFailed={onFinishFailed}
              className="mb-6 pt-2 flex flex-col bg-custom-gradient w-full max-w-[470px] justify-center items-center rounded-xl mobile-m:rounded-3xl mobile-m:px-2"
            >
              <div className="flex flex-col justify-center items-center w-full max-w-[380px]">
                <div className="w-full flex justify-between items-center">
                  {tabKey === 2 && (
                    <Button
                      className="bg-transparent border-none text-[#A8BDC1] rounded-[42px] p-4 hover:bg-[#e8f3ff] hover:text-[#7e5aeb]"
                      icon={<ArrowLeftOutlined />}
                      onClick={() => setTabKey(1)}
                    />
                  )}
                  <Typography.Text
                    className={`font-urbanist text-center text-3xl text-[#0F0F1C] w-full font-semibold ${tabKey === 1 ? "ml-8" : "mr-8"}`}
                  >
                    Sign Up
                  </Typography.Text>
                  {tabKey === 1 && (
                    <Button
                      className="bg-transparent border-none text-[#A8BDC1] rounded-[42px] p-4 hover:bg-[#fefefe] hover:text-[#7e5aeb]"
                      icon={<ArrowRightOutlined />}
                      onClick={() => setTabKey(2)}
                    />
                  )}
                </div>
                <Typography.Text className="m-4 font-urbanist text-center leading-4 text-[12px] w-full text-normal text-[#5d6769] mobile-m:text-sm">
                  Welcome to Docupdate.
                </Typography.Text>

                {/* Tab container with absolute positioning */}
                <div
                  className={`relative w-full ${tabKey === 1 ? "min-h-[760px]" : "min-h-[920px]"} overflow-hidden`}
                >
                  <div
                    className={`absolute inset-0 transition-transform duration-700 ease-in-out transform ${
                      tabKey === 1 ? "translate-x-0" : "-translate-x-full"
                    }`}
                  >
                    <Row
                      gutter={[12, tabKey === 1 ? 16 : 8]}
                      className="px-4 xs:px-0 lg:!gap-y-3"
                      wrap
                      align={"middle"}
                      justify={"center"}
                    >
                      {/* First Tab Fields */}
                      <Col span={12} className="p-0">
                        <Form.Item
                          name={"firstName"}
                          rules={[
                            {
                              required:
                                currentForm === "prescriber-signup"
                                  ? true
                                  : false,
                              message: "*First Name is Required",
                            },
                          ]}
                        >
                          <FloatInput
                            name={"firstName"}
                            placeholder={"First Name *"}
                            required
                            type="text"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="p-0">
                        <Form.Item
                          name={"lastName"}
                          rules={[
                            {
                              required:
                                currentForm === "prescriber-signup"
                                  ? true
                                  : false,
                              message: "*Last Name is Required",
                            },
                          ]}
                        >
                          <FloatInput
                            name={"lastName"}
                            type="text"
                            placeholder={"Last Name *"}
                            required
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="npiNumber"
                          rules={[
                            {
                              required:
                                currentForm === "prescriber-signup"
                                  ? true
                                  : false,
                              message: "*NPI is Required",
                            },
                            validateNPIPattern(),
                            validateExactLength("NPI", 10),
                            validateMaxValue("NPI", 2147483647),
                          ]}
                          validateFirst
                        >
                          <FloatInput
                            name={"npiNumber"}
                            type="text"
                            placeholder={"NPI *"}
                            maxLength={10}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => allowNumOnly(e, form, "npiNumber")}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="deaNumber"
                          rules={[
                            validateDeaPattern(),
                            { validator: validateDeaNumberFormat(form) },
                          ]}
                          validateFirst
                          initialValue={""}
                        >
                          <FloatInput
                            name={"deaNumber"}
                            type="text"
                            placeholder={"DEA Number"}
                            maxLength={9}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="address"
                          rules={[
                            {
                              required:
                                currentForm === "prescriber-signup"
                                  ? true
                                  : false,
                              message: "*Street Address is Required",
                            },
                          ]}
                        >
                          <FloatInput
                            name={"address"}
                            required
                            type="text"
                            placeholder={"Street Address *"}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="city"
                          rules={[
                            {
                              required:
                                currentForm === "prescriber-signup"
                                  ? true
                                  : false,
                              message: "*City is Required",
                            },
                          ]}
                        >
                          <FloatInput
                            name={"city"}
                            type="text"
                            placeholder={"City *"}
                            required
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="mobile-m:my-2 xs:my-0">
                        <Form.Item
                          name="state"
                          rules={[
                            {
                              required:
                                currentForm === "prescriber-signup"
                                  ? true
                                  : false,
                              message: "*State is Required",
                            },
                          ]}
                        >
                          <FloatInput
                            name={"state"}
                            type="text"
                            placeholder={"State *"}
                            required
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="mobile-m:my-2 xs:my-0">
                        <Form.Item
                          name="zipCode"
                          rules={[
                            {
                              required:
                                currentForm === "prescriber-signup"
                                  ? true
                                  : false,
                              message: "*Zip Code is Required",
                            },
                            {
                              pattern: /^\d{5}$/,
                              message: "Zip Code must be of 5 digits",
                            },
                          ]}
                          validateFirst
                        >
                          <FloatInput
                            name={"zipCode"}
                            type="text"
                            placeholder={"Zip Code *"}
                            maxLength={5}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => allowNumOnly(e, form, "zipCode")}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} className="relative md:mb-1">
                        <span
                          className={`absolute top-1/2 pl-[4.5rem] ${form.isFieldTouched("phoneNumber") || isPhoneHasError ? "-translate-y-[75%] md:-translate-y-[55%] lg:-translate-y-[61%]" : "-translate-y-[50%]"}  text-[#aaa] pointer-events-none ${showPlaceholder ? "z-10" : "-z-10"} text-sm`}
                        >
                          Mobile Phone Number*
                        </span>
                        <Form.Item
                          name="phoneNumber"
                          rules={[
                            {
                              required:
                                currentForm === "prescriber-signup"
                                  ? true
                                  : false,
                              message: "*Mobile Number is Required",
                            },
                            phoneNumberLength(countryData),
                          ]}
                          validateFirst
                        >
                          <PhoneInput
                            country={"us"}
                            disableDropdown
                            onlyCountries={["us"]}
                            countryCodeEditable={false}
                            onChange={validatePhone}
                            enableLongNumbers={
                              (countryData as CountryData)?.format.split(".")
                                .length -
                                1 <
                              15
                                ? false
                                : true
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="signupEmail"
                          rules={[
                            {
                              required:
                                currentForm === "prescriber-signup"
                                  ? true
                                  : false,
                              message: "*Email Address is Required",
                            },
                            emailPattenValidation(),
                          ]}
                          validateFirst
                        >
                          <FloatInput
                            name={"signupEmail"}
                            type="text"
                            placeholder={"Email Address *"}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} className="mb-2 xs:mb-0">
                        <Form.Item
                          name="signupPassword"
                          rules={[
                            {
                              required:
                                currentForm === "prescriber-signup"
                                  ? true
                                  : false,
                              message: "*Password is Required",
                            },
                            passwordPatternValidation(),
                          ]}
                          validateFirst
                          hasFeedback
                        >
                          <FloatInput
                            name={"signupPassword"}
                            type="password"
                            placeholder={"Password *"}
                            required
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="confirmPassword"
                          rules={[
                            {
                              required:
                                currentForm === "prescriber-signup"
                                  ? true
                                  : false,
                              message: "*Confirm Password is Required",
                            },
                            ({ getFieldValue }) =>
                              confirmPasswordValidation(getFieldValue),
                          ]}
                          validateFirst
                          hasFeedback
                        >
                          <FloatInput
                            name={"confirmPassword"}
                            type="password"
                            placeholder={"Confirm Password *"}
                            required
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>

                  <div
                    className={`absolute inset-0 transition-transform duration-700 ease-in-out transform ${
                      tabKey === 2 ? "translate-x-0" : "translate-x-full"
                    }`}
                  >
                    <Row className="px-4">
                      {/* Second Tab Content */}
                      <div
                        className={`upload-wrapper-container ${onUploadError && "upload-wrapper-container-has-error"}`}
                      >
                        <Upload.Dragger {...props}>
                          <Typography.Text
                            className={`text-[#7E5AE2] font-semibold leading-tight ${onUploadError && "text-red-500"}`}
                          >
                            Please upload a clear picture of US government ID
                            (Driver license, Passport, etc.)
                          </Typography.Text>
                          <Button
                            htmlType="button"
                            className="my-3 bg-[#7E5AE2] text-white font-medium border-none w-full max-w-[200px]"
                          >
                            Browse
                          </Button>
                        </Upload.Dragger>
                      </div>
                      <div
                        className={
                          onUploadError
                            ? "block text-red-500 text-xs font-bold text-center w-full"
                            : "hidden"
                        }
                      >
                        *{onUploadError}
                      </div>
                      <div className="mt-4 flex justify-center items-center mx-auto">
                        <Form.Item
                          name={"privacyPolicyAgreement"}
                          valuePropName="checked"
                          rules={[policyCheckboxValidation(tabKey)]}
                          className="consent-form"
                        >
                          <Checkbox className="custom-checkbox font-urbanist text-justify text-xs font-normal text-[#5d6769] leading-tight mobile-m:max-w-[285px] mobile-lg:text-[14px] mobile-lg:max-w-[320px] xs:text-[15px] xs:max-w-[400px]">
                            By submitting this form and signing up for texts,
                            you consent to receive text messages from Docupdate
                            at the number provided, including message sent by
                            autodialer. Consent is not condition of purchase.
                            Msg & data rates may apply. Msg frequency varies
                            <span className="font-bold">&#46;</span> Unsubscribe
                            at any time by replying STOP or clicking the
                            unsubscribe link (where available). I read and agree
                            with the Docupdate
                            <Link
                              to={"https://www.docupdate.io/ccpa"}
                              target="_blank"
                              className="text-[#4169c4]"
                            >
                              {" "}
                              CCPA Policy,{" "}
                              <Link
                                to={"https://www.docupdate.io/privacy-policy"}
                                target="_blank"
                                className="text-[#4169c4]"
                              >
                                Privacy Policy
                              </Link>
                              ,{" "}
                            </Link>
                            and{" "}
                            <Link
                              to={"https://www.docupdate.io/tos"}
                              target="_blank"
                              className="text-[#4169c4]"
                            >
                              Terms of Service
                            </Link>
                          </Checkbox>
                        </Form.Item>
                      </div>
                      <div className="flex flex-col justify-center items-center mobile-m:mx-auto">
                        <Typography.Text className="ml-6 mr-3 font-urbanist text-xs font-normal text-[#5d6769] leading-tight text-justify mobile-m:max-w-[265px] mobile-lg:text-[14px] mobile-lg:max-w-[290px] xs:text-[15px] xs:max-w-[400px]">
                          By signing up, you verify the information is correct
                          and true. You (the "Covered Entity") may provide us
                          (the "Business Associate") with Protected Health
                          Information, as defined under the Health Information
                          Portability and Accountability Act of 1996 ("HIPAA"),
                          for us to provide certain services for or on your
                          behalf. This makes us your Business Associate for
                          purposes of HIPAA and requires a Business Associate
                          Agreement to be entered into between us. By providing
                          your digital signature below, you hereby agree to the
                          terms and conditions of the{" "}
                          <Link
                            className="text-[#4169c4] leading-snug"
                            to="https://www.docupdate.io/hipaa"
                            target="_blank"
                          >
                            HIPAA Business Associate Agreement.
                          </Link>
                        </Typography.Text>
                        <Typography.Title className="mt-3 mb-0 font-urbanist text-black tracking-tight text-base">
                          Add Your Signature
                        </Typography.Title>
                        <div className={`signature-wrapper`}>
                          <SignatureCanvas
                            penColor="#7E5AE2B2"
                            canvasProps={{
                              className: `signature ${onSignatureError && "signature-has-error"}`,
                            }}
                            ref={sigRef}
                            onBegin={(event: MouseEvent) =>
                              setOnSignatureError("")
                            }
                          />
                          <p className="text-signature">eSignature</p>
                          <button
                            className="clear-btn"
                            type="button"
                            onClick={clearSignature}
                          >
                            Clear
                          </button>
                        </div>
                        <div
                          className={
                            onSignatureError
                              ? "block text-red-500 text-xs font-bold text-center w-full"
                              : "hidden"
                          }
                        >
                          *{onSignatureError}
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
                <Col span={24} className="text-center lg:hidden">
                  <Typography.Text className="font-urbanist font-semibold text-sm text-[#050505]">
                    Already have an account?
                    <span
                      className="text-[#7E5AE2] cursor-pointer font-bold"
                      onClick={() => navigate("/login-new")}
                    >
                      {" "}
                      Sign In Now
                    </span>
                  </Typography.Text>
                </Col>
                <Col
                  span={24}
                  className="my-3 mb-6 px-4 flex justify-center items-center w-full"
                >
                  <Button
                    htmlType="submit"
                    className="bg-[#7E5AE2] text-white border-none w-full font-semibold rounded-md text-sm max-w-[644px] disabled:opacity-45"
                    loading={isLoading}
                    iconPosition="end"
                  >
                    {tabKey === 1 ? "NEXT" : "SIGN UP"}
                  </Button>
                </Col>
              </div>
            </Form>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default SignupV1;
