import "./advisoryboard.scss";
import advisoryBoardPhone from "../../assets/advisoryBoardPhone.png";
import { Button, Collapse, Form, Typography } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { COLLAPSE_ITEMS } from "../../config/constants";
import FloatInput from "../../components/FloatInput/floatInput";
import {
  allowNumOnly,
  emailPattenValidation,
  phoneNumberLength,
  validateExactLength,
  validateMaxValue,
  validateNPIPattern,
} from "../../utils/methods/form-validations";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { useState } from "react";
import { StoreValue } from "antd/es/form/interface";
import { useQuestion } from "../../utils/hooks/useQuestion";
import { useForm } from "../../utils/hooks/useForm";

const AdvisoryBoard = () => {
  const { form } = useForm();
  const { isApiLoading, postQuestion, showPlaceholder, setShowPlaceholder } =
    useQuestion();
  const [countryData, setCountryData] = useState<CountryData | undefined>(
    undefined,
  );
  const [isPhoneHasError, setIsPhoneHasError] = useState<boolean>(false);

  const validatePhone = (_: string, countryData: CountryData) => {
    const phoneValue = form.getFieldValue("questions_phonenumber");
    setShowPlaceholder(phoneValue.length < 2);
    setCountryData(countryData);
  };
  const onFinish = (values: StoreValue) => {
    postQuestion(values, form);
  };

  const onFinishFailed = () => {
    const checkPhoneNumber =
      form.getFieldError("questions_phonenumber")?.length > 0;
    setIsPhoneHasError(checkPhoneNumber);
  };

  return (
    <div className="flex flex-col justify-center items-center w-full max-w-[1080px] mt-auto mb-8 mx-5 px-2 mobile-lg:px-4 sm:mx-7 md:px-2 md:mx-5 xl:mx-auto xl:px-6 2xl:max-w-[1440px] 2xl:px-12">
      <div className="mt-6 flex flex-col justify-center items-center w-full h-full gap-y-6 lg:mb-2 lg:hidden">
        <div className="flex flex-col items-center gap-y-6 mx-auto sm:max-w-full sm:mx-0 sm:gap-x-2 lg:gap-y-2">
          <Typography.Title className="font-urbanist font-bold text-center mb-2 text-3xl">
            Paid Opportunities Delivered to Your Phone
          </Typography.Title>
          <Typography.Text className="font-urbanist text-[#5d6769] text-lg text-justify">
            Earn extra income within 2 minutes. Opportunities for short term and
            long term engagements with the leading innovators in healthtech,
            pharma,  and medical devices.
          </Typography.Text>
        </div>
        <div className="my-6 w-full h-full flex justify-center items-center">
          <img
            src={advisoryBoardPhone}
            alt="advisory-phone"
            className="w-full max-w-full object-cover lg:object-contain"
          />
        </div>
      </div>
      <div className="hidden lg:flex lg:justify-center lg:items-center lg:gap-x-6 mx-auto px-6 lg:mt-9">
        <div className="pt-8 flex flex-col items-center gap-y-6 mx-auto sm:max-w-full sm:mx-0 sm:gap-x-2 lg:gap-y-2 lg:max-w-[280px] ml:pt-0">
          <Typography.Title className="font-urbanist font-bold text-center mb-2 text-3xl lg:text-left ">
            Paid Opportunities Delivered to Your Phone
          </Typography.Title>
          <Typography.Text className="font-urbanist text-[#5d6769] text-lg text-justify lg:text-[11px] leading-4">
            Earn extra income within 2 minutes. Opportunities for short term and
            long term engagements with the leading innovators in healthtech,
            pharma,  and medical devices.
          </Typography.Text>
        </div>
        <div className="mb-6 w-full flex justify-center items-center">
          <img
            src={advisoryBoardPhone}
            alt="advisory-phone"
            className="w-full max-w-full max-h-[670px] lg:object-contain"
          />
        </div>
      </div>
      <div className="mt-6 flex flex-col items-center justify-center gap-y-4 w-full md:mt-3 md:gap-y-2">
        <Typography.Title className="font-urbanist font-bold text-center mb-2 text-3xl">
          Frequently Asked Questions
        </Typography.Title>
        <Typography.Text className="block font-urbanist text-center text-[#5d6769] lg:text-base">
          Have additional questions? Send us an email at{" "}
          <span className="text-[#7e5ae2]">support@docupdate.io</span>
        </Typography.Text>
        <div id="board_collapse" className="font-urbanist custom-flex-wrapper">
          <Collapse
            size="large"
            bordered={false}
            expandIcon={(panel) => (
              <DownOutlined
                className="!text-[0.8rem]"
                {...(panel.isActive && { rotate: -180 })}
              />
            )}
            expandIconPosition="end"
            items={[COLLAPSE_ITEMS[4]]}
          />
          <Collapse
            size="large"
            bordered={false}
            expandIcon={(panel) => (
              <DownOutlined
                className="!text-[0.8rem]"
                {...(panel.isActive && { rotate: -180 })}
              />
            )}
            expandIconPosition="end"
            items={[COLLAPSE_ITEMS[5]]}
          />
          <Collapse
            size="large"
            bordered={false}
            expandIcon={(panel) => (
              <DownOutlined
                className="!text-[0.8rem]"
                {...(panel.isActive && { rotate: -180 })}
              />
            )}
            expandIconPosition="end"
            items={[COLLAPSE_ITEMS[6]]}
          />
          <Collapse
            size="large"
            bordered={false}
            expandIcon={(panel) => (
              <DownOutlined
                className="!text-[0.8rem]"
                {...(panel.isActive && { rotate: -180 })}
              />
            )}
            expandIconPosition="end"
            items={[COLLAPSE_ITEMS[7]]}
          />
        </div>
      </div>
      <Form
        className="mt-6 bg-custom-gradient flex flex-col items-center justify-center gap-y-4 w-full rounded-xl p-4 lg:rounded-[25px] ml:px-9"
        onFinish={onFinish}
        form={form}
        onFinishFailed={onFinishFailed}
      >
        <Typography.Title className="font-urbanist font-bold text-center mb-2 text-3xl">
          Questions?
        </Typography.Title>
        <div className="custom-flex-wrapper">
          <Form.Item
            name="questions_name"
            rules={[{ required: true, message: "*Name is Required" }]}
            validateFirst
          >
            <FloatInput
              name={"questions_name"}
              type="text"
              placeholder={"Name *"}
            />
          </Form.Item>
          <Form.Item
            name={"questions_npiNumber"}
            rules={[
              { required: true, message: "*NPI is Required" },
              validateNPIPattern(),
              validateExactLength("NPI", 10),
              validateMaxValue("NPI", 2147483647),
            ]}
            validateFirst
          >
            <FloatInput
              name={"questions_npiNumber"}
              placeholder={"NPI Number *"}
              required
              type="text"
              maxLength={10}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                allowNumOnly(e, form, "questions_npiNumber")
              }
            />
          </Form.Item>
          <div className="mobile-div relative">
            <span
              className={`absolute top-1/2 pl-[4.5rem] ${form.isFieldTouched("questions_phonenumber") || isPhoneHasError ? "-translate-y-[75%] md:-translate-y-[55%] lg:-translate-y-[61%]" : "-translate-y-[50%]"}  text-[#aaa] pointer-events-none ${showPlaceholder ? "z-10" : "-z-10"} text-xs mobile-m:text-sm`}
            >
              Mobile Phone Number*
            </span>
            <Form.Item
              name="questions_phonenumber"
              rules={[
                { required: true, message: "*Mobile Number is Required" },
                phoneNumberLength(countryData),
              ]}
              validateFirst
            >
              <PhoneInput
                country={"us"}
                onChange={validatePhone}
                onlyCountries={["us"]}
                disableDropdown
                countryCodeEditable={false}
                enableLongNumbers={
                  (countryData as CountryData)?.format.split(".").length - 1 <
                  15
                    ? false
                    : true
                }
              />
            </Form.Item>
          </div>
          <Form.Item
            name="questions_email"
            rules={[
              { required: true, message: "*Email Address is Required" },
              emailPattenValidation(),
            ]}
            validateFirst
          >
            <FloatInput
              name={"questions_email"}
              type="text"
              placeholder={"Email Address *"}
            />
          </Form.Item>
          <Form.Item
            className="pb-10"
            name="questions_question"
            rules={[{ required: true, message: "*Question is Required" }]}
            validateFirst
          >
            <FloatInput
              name={"questions_question"}
              type="textArea"
              placeholder={"Question *"}
              rows={3}
              draggable={false}
            />
          </Form.Item>
          <div className="flex flex-col w-full items-center justify-center gap-y-4">
            <Button
              htmlType="submit"
              className="mt-1 font-urbanist font-bold bg-[#7E5AE2] text-white border-none w-full rounded-md text-sm max-w-[360px] mx-auto disabled:opacity-45 md:text-base 2xl:max-w-[420px]"
              loading={isApiLoading}
              iconPosition="end"
            >
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AdvisoryBoard;
