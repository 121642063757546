import "./sidenav.css";
import { Menu, Typography } from "antd";
import { useSideBarMenu } from "../../utils/content/sidebar-menus";
import AppleIcon from "../../assets/applestoreImageBtn.png";
import docupdateWhite from "../../assets/docupdate-white-icon.png";
import PlayStoreIcon from "../../assets/googlePlayStoreBadge.webp";
import { Link } from "react-router-dom";

const SideNav = () => {
  const { MENU_INFO } = useSideBarMenu();
  return (
    <div className="sider_setup hidden overflow-y-auto sm:flex sm:flex-col sm:gap-y-[3rem] sm:w-full sm:h-full sm:max-w-[260px]">
      <img src={docupdateWhite} alt="white-logo" />
      <Menu
        mode="inline"
        className="bg-transparent w-full h-full border-none flex flex-col gap-y-4 max-h-[425px]"
        items={MENU_INFO}
        defaultSelectedKeys={["1"]}
      />
      <div className="flex flex-col gap-y-4">
        <Typography.Text className="font-urbanist font-bold text-2xl leading-tight">
          Download the Prescriber app now
        </Typography.Text>
        <Link
          target="_blank"
          to={"https://apps.apple.com/us/app/prescriber/id6478404244"}
          className="flex justify-center items-center"
        >
          <img
            src={AppleIcon}
            alt="apple-icon"
            className="object-contain w-full h-full bg-white rounded-[10.8px]"
          />
        </Link>
        <Link
          target="_blank"
          to={
            "https://play.google.com/store/apps/details?id=com.impericus.prescriber"
          }
          className="flex justify-center items-center"
        >
          <img
            src={PlayStoreIcon}
            alt="playstore-icon"
            className="object-cover w-full"
          />
        </Link>
      </div>
    </div>
  );
};

export default SideNav;
