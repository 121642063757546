import { PaperClipOutlined, TagOutlined } from "@ant-design/icons";
import { ReactComponent as AccountSettingsIcon } from "../../assets/AccountSettingsIcon.svg";
import { ReactComponent as ProductsIcon } from "../../assets/ProductsIcon.svg";
import { ReactComponent as SupportIcon } from "../../assets/SupportIcon.svg";
import { ReactComponent as SignoutIcon } from "../../assets/SignOutIcon.svg";

import { useAuth } from "../hooks/useAuth";
import { GetProp, MenuProps, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const useSideBarMenu = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [open, setOpen] = useState(false);

  type MenuItem = GetProp<MenuProps, "items">[number];

  const handleMenuClick = (url: string) => {
    if (url === "Support") {
      window.location.href = "mailto:support@docupdate.io";
    } else {
      navigate(url);
    }
    setOpen(false);
  };

  const MENU_INFO: MenuItem[] = [
    {
      key: "1",
      label: <Tooltip title="Account Setting">Account Setting</Tooltip>,
      icon: <AccountSettingsIcon className="w-full object-cover max-w-10" />,
      onClick: () => handleMenuClick("/advisory-board-portal-new"),
      className:
        "flex items-center font-semibold font-urbanist text-[15px] bg-transparent p-[1.68rem_1rem] text-white",
    },
    {
      key: "2",
      label: "Products",
      icon: <ProductsIcon className="w-full object-cover max-w-10" />,
      children: [
        {
          key: "21",
          label: <Tooltip title="Advisory Board">Advisory Board</Tooltip>,
          icon: <TagOutlined className="w-full object-cover max-w-5" />,
          className:
            "flex items-center font-semibold font-urbanist text-[15px] bg-transparent p-[1.68rem_1rem] text-white product-menu",
          onClick: () => handleMenuClick("/advisory-board-new"),
        },
        {
          key: "22",
          label: <Tooltip title="Prescriber App">Prescriber App</Tooltip>,
          icon: <PaperClipOutlined className="w-full object-cover max-w-5" />,
          className:
            "flex items-center font-semibold font-urbanist text-[15px] bg-transparent p-[1.68rem_1rem] text-white product-menu",
          onClick: () => handleMenuClick("/ePrescribe"),
        },
      ],
    },
    {
      key: "3",
      label: "Support",
      icon: <SupportIcon className="w-full object-cover max-w-10" />,
      className:
        "flex items-center font-semibold font-urbanist text-[15px] bg-transparent p-[1.68rem_1rem] text-white",
      onClick: () => handleMenuClick("Support"),
    },
    {
      key: "4",
      label: "Sign Out",
      icon: <SignoutIcon className="w-full object-cover max-w-10" />,
      className:
        "flex items-center font-semibold font-urbanist text-[15px] bg-transparent p-[1.68rem_1rem] text-white",
      onClick: () => logout(),
    },
  ];

  const MENU_INFO_MOBILE: MenuItem[] = [
    {
      key: "1",
      label: "Account Setting",
      icon: <AccountSettingsIcon className="w-full object-cover max-w-10" />,
      onClick: () => handleMenuClick("/advisory-board-portal-new"),
      className:
        "flex items-center font-semibold font-urbanist text-[15px] bg-transparent p-[1.68rem_1rem] text-white",
    },
    {
      key: "2",
      label: "Products",
      icon: <ProductsIcon className="w-full object-cover max-w-10" />,
      children: [
        {
          key: "21",
          label: "Advisory Board",
          icon: <TagOutlined className="w-full object-cover max-w-5" />,
          className:
            "flex items-center font-semibold font-urbanist text-[15px] bg-transparent p-[1.68rem_1rem] text-white product-menu",
          onClick: () => handleMenuClick("/advisory-board-new"),
        },
        {
          key: "22",
          label: "Prescriber App",
          icon: <PaperClipOutlined className="w-full object-cover max-w-5" />,
          className:
            "flex items-center font-semibold font-urbanist text-[15px] bg-transparent p-[1.68rem_1rem] text-white product-menu",
          onClick: () => handleMenuClick("/ePrescribe"),
        },
      ],
    },
    {
      key: "3",
      label: "Support",
      icon: <SupportIcon className="w-full object-cover max-w-10" />,
      className:
        "flex items-center font-semibold font-urbanist text-[15px] bg-transparent p-[1.68rem_1rem] text-white",
      onClick: () => handleMenuClick("Support"),
    },
    {
      key: "4",
      label: "Sign Out",
      icon: <SignoutIcon className="w-full object-cover max-w-10" />,
      className:
        "flex items-center font-semibold font-urbanist text-[15px] bg-transparent p-[1.68rem_1rem] text-white",
      onClick: () => logout(),
    },
  ];

  return {
    MENU_INFO,
    MENU_INFO_MOBILE,
    open,
    setOpen,
  };
};
