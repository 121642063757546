// userUpdateSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserPhoneNoDetailPayload } from "../../types/global.types";

const initialState: { userPhoneNoDetail: IUserPhoneNoDetailPayload } = {
  userPhoneNoDetail: {
    email: "",
    updatedPhoneNo: "",
  },
};

export const userPhoneDetailSlice = createSlice({
  name: "userDetail",
  initialState,
  reducers: {
    setUserDetail: (
      state,
      action: PayloadAction<IUserPhoneNoDetailPayload>,
    ) => {
      state.userPhoneNoDetail = { ...action.payload };
    },
  },
});

export const userPhoneDetailActions = userPhoneDetailSlice.actions;
export default userPhoneDetailSlice.reducer;
