import { FormInstance, RuleObject } from "antd/es/form";
import { NamePath, StoreValue } from "antd/es/form/interface";
import {
  emailRegex,
  passwordRegex,
  deaRegex,
  npiRegex,
} from "../content/regex";
import useMakeApiRequest from "../../api/useMakeApiRequest";
import { CountryData } from "react-phone-input-2";
import { useState } from "react";

export const emailPattenValidation = () => {
  return {
    validator: (_: RuleObject, value: StoreValue) => {
      if (emailRegex.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject("Invalid Email Format!");
    },
  };
};

export const validateMaxLength = (fieldName: string, maxLength: number) => {
  return {
    validator: (_: RuleObject, value: StoreValue) => {
      if (value.length < maxLength) {
        return Promise.reject(
          `${fieldName} should be ${maxLength} characters long`,
        );
      }
      return Promise.resolve();
    },
  };
};

export const validateExactLength = (fieldName: string, exactLength: number) => {
  return {
    validator: (_: RuleObject, value: StoreValue) => {
      if (value.length !== exactLength) {
        return Promise.reject(`${fieldName} must be of ${exactLength} digits`);
      }
      return Promise.resolve();
    },
  };
};

export const validateNPIPattern = () => {
  return {
    validator: (_: RuleObject, value: StoreValue) => {
      if (npiRegex.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject("*NPI must contain digits only!");
    },
  };
};

export const validateMaxValue = (fieldName: string, maxValue: number) => {
  return {
    validator: (_: RuleObject, value: StoreValue) => {
      if (Number(value) > maxValue) {
        return Promise.reject(`*Invalid ${fieldName}`);
      }
      return Promise.resolve();
    },
  };
};

export const validateDeaPattern = () => {
  return {
    validator: (_: RuleObject, value: StoreValue) => {
      if (!value) {
        return Promise.resolve();
      }
      if (deaRegex.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject("DEA number is invalid.");
    },
  };
};

export const passwordPatternValidation = () => {
  return {
    validator: (_: RuleObject, value: StoreValue) => {
      if (passwordRegex.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(
        "Password should be 1 digit, 1special, 1 large and 8 character long!",
      );
    },
  };
};

export const confirmPasswordValidation = (
  getFieldValue: (name: NamePath) => StoreValue,
) => {
  return {
    validator: (_: RuleObject, value: StoreValue) => {
      const password = getFieldValue("signupPassword");
      if (!password) {
        return Promise.reject("*Please Enter Password!");
      }
      if (!value || getFieldValue("signupPassword") === value) {
        return Promise.resolve();
      }
      return Promise.reject("The new password that you entered do not match!");
    },
  };
};

export const policyCheckboxValidation = (tabKey: number) => {
  return {
    validator: (_: RuleObject, checked: any) => {
      if (tabKey === 4 && !checked) {
        return Promise.reject(new Error("*You must agree to HIPAA Agreement"));
      }
      if (tabKey === 2 && !checked) {
        return Promise.reject(new Error("*You must agree to HIPAA Agreement"));
      }
      return Promise.resolve();
    },
  };
};

export const phoneNumberLength = (countryData: CountryData | undefined) => {
  return {
    validator: (_: RuleObject, value: StoreValue) => {
      if (!value) {
        return Promise.resolve();
      }
      if (
        countryData &&
        countryData?.format.split(".").length - 1 !== value.length
      ) {
        return Promise.reject(new Error("*Invalid Phone Number!"));
      }
      return Promise.resolve();
    },
  };
};

export const allowNumOnly = (
  e: React.ChangeEvent<HTMLInputElement>,
  form: FormInstance,
  fieldName: string,
) => {
  const numericValue = e.target.value.replace(/\D/g, "");
  form.setFields([
    {
      name: fieldName,
      value: numericValue,
      errors: [],
    },
  ]);
};

export const useAsyncValidate = () => {
  const { makeApiRequest } = useMakeApiRequest();
  const [isDeaLoading, setIsDeaLoading] = useState("idle");

  const validateDeaNumberFormat = (
    form: FormInstance,
    isDeaDisabled?: boolean,
  ) => {
    return async (_: RuleObject, value: StoreValue) => {
      if (!value) {
        return Promise.resolve();
      }

      if (value.length !== 9) {
        return Promise.resolve(); // Skip validation if the value isn't 9 characters
      }

      if (isDeaDisabled && !form.isFieldTouched("board_deaNumber")) {
        return Promise.resolve();
      }

      setIsDeaLoading("deaLoading");
      try {
        const lastNameValue =
          form?.getFieldValue("lastName") ||
          form?.getFieldValue("board_lastName");
        const result = await makeApiRequest(
          "/auth/validate-dea",
          "POST",
          {},
          {
            dea_number: value,
            last_name: lastNameValue,
          },
          "application/json",
          true,
        );

        if (result?.status === 200) {
          setIsDeaLoading("deaSuccess");
          return Promise.resolve(result?.message);
        } else if (result?.error) {
          setIsDeaLoading("deaError");
          return Promise.reject("DEA number is invalid.");
        }
      } catch (error: any) {
        setIsDeaLoading("deaError");
        return Promise.reject("DEA number is invalid.");
      }
    };
  };

  const checkDeaError = async (
    value: string,
    form: FormInstance,
    isSubmitting: boolean,
  ) => {
    if (!value) {
      form.setFields([
        {
          name: "board_deaNumber",
          value: value,
          errors: [],
        },
      ]);
      return true;
    }

    if (value.length !== 9) {
      form.setFields([
        {
          name: "board_deaNumber",
          value: value,
          errors: [],
        },
      ]);
      return true; // Skip validation if the value isn't 9 characters
    }
    if (isSubmitting) {
      setIsDeaLoading("deaLoading");
    }
    try {
      const lastNameValue = form?.getFieldValue("board_lastName");
      const result = await makeApiRequest(
        "/auth/validate-dea",
        "POST",
        {},
        {
          dea_number: value,
          last_name: lastNameValue,
        },
        "application/json",
        true,
      );

      if (result?.status === 200) {
        setIsDeaLoading("deaSuccess");
        form.setFields([
          {
            name: "board_deaNumber",
            value: value,
            errors: [],
          },
        ]);
        return true;
      } else if (result?.error) {
        setIsDeaLoading("deaError");
        form.setFields([
          {
            name: "board_deaNumber",
            errors: ["DEA number is invalid."],
          },
        ]);
        return false;
      }
    } catch (error: any) {
      setIsDeaLoading("deaError");
      form.setFields([
        {
          name: "board_deaNumber",
          errors: ["DEA number is invalid."],
        },
      ]);
      return false;
    }
  };

  return {
    validateDeaNumberFormat,
    checkDeaError,
    isDeaLoading: isDeaLoading === "deaLoading",
  };
};
