// userUpdateSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserDetails } from "../../types/global.types";

const initialState: { userDetail: IUserDetails } = {
  userDetail: {
    id: 0,
    board_firstName: "",
    board_lastName: "",
    board_email: "",
    board_phonenumber: "",
    board_npiNumber: "",
    board_address: "",
    board_deaNumber: null,
    board_state: "",
    board_city: "",
    board_zipCode: "",
    board_message_freq: 0,
  },
};

export const userDetailSlice = createSlice({
  name: "userDetail",
  initialState,
  reducers: {
    setUserDetail: (state, action: PayloadAction<IUserDetails>) => {
      state.userDetail = { ...action.payload };
    },
  },
});

export const userDetailActions = userDetailSlice.actions;
export default userDetailSlice.reducer;
