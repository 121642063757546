import useMakeApiRequest from "../../api/useMakeApiRequest";
import { StoreValue } from "antd/es/form/interface";
import { FormInstance, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { userPhoneDetailActions } from "../../store/reducers/userPhoneNoDetailReducer";
import { useForm } from "./useForm";

export const usePhoneUpdate = () => {
  const {
    handleApiRequest,
    makeApiRequest,
    isApiLoading: isUpdatingPhoneNumber,
  } = useMakeApiRequest();
  const { form } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = useSelector((state: RootState) => state.auth.authUser);

  const updateNewPhoneNo = (values: StoreValue) => {
    handleApiRequest(
      () =>
        makeApiRequest(
          "/user/update-phone",
          "PUT",
          {},
          {
            email: values.email,
            newPhoneNumber: `${values.updatedPhoneNo}`,
          },
        ),
      (response: any) => {
        if (response.status === 200) {
          message.success(response.message);
          form.resetFields();
        }
      },
      "Unable to Make Update Request!",
      true,
    );
  };

  const verifyPhoneonCreation = (values: StoreValue) => {
    handleApiRequest(
      () =>
        makeApiRequest(
          "/user/resend-phone-verification",
          "POST",
          {},
          {
            userName: values.user_email ?? authUser?.email,
            phoneNumber: `+${values.update_mobile}`,
          },
        ),
      (response: any) => {
        if (response.status === 200) {
          message.success("Phone verification code sent successfully");
          navigate("/verify-otp", {
            state: {
              email: values.user_email ?? authUser?.email,
              password: authUser?.password,
              phoneNumber: values.update_mobile,
              enablePhoneUpdate: "/update-mobileno",
            },
          });
          dispatch(
            userPhoneDetailActions.setUserDetail({
              email: values.user_email ?? authUser?.email,
              updatedPhoneNo: `+${values.update_mobile}`,
            }),
          );
        }
      },
      "Unable to Make Phone Verify Request!",
      true,
    );
  };

  return {
    isUpdatingPhoneNumber,
    updateNewPhoneNo,
    verifyPhoneonCreation,
  };
};
