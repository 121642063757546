import { Button, Col, Form, message, Row, Typography } from "antd";
import { useForm } from "../../utils/hooks/useForm";
import { useAuth } from "../../utils/hooks/useAuth"; // Import the useAuth hook
import { useCountDown } from "../../utils/hooks/useCountDown";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { phoneNumberLength } from "../../utils/methods/form-validations";
import { useState } from "react";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { usePhoneUpdate } from "../../utils/hooks/usePhoneUpdate";

const UpdateMobilePhone = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log({ location });
  const { form } = useForm();
  const { isUpdatingPhoneNumber, verifyPhoneonCreation } = usePhoneUpdate();
  const [countryData, setCountryData] = useState<CountryData | undefined>(
    undefined,
  );
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true);
  const [isPhoneHasError, setIsPhoneHasError] = useState<boolean>(false);

  const onFinish = async (values: any) => {
    try {
      verifyPhoneonCreation({ ...values, user_email: location.state.email });
    } catch (error) {
      onFinishFailed(error);
      message.error("Failed to Update Phone Number");
    }
  };

  const onFinishFailed = (errorInfor: any) => {
    const checkPhoneNumber = form.getFieldError("update_mobile")?.length > 0;
    setIsPhoneHasError(checkPhoneNumber);
  };

  const validatePhone = (value: string, countryData: CountryData) => {
    const phoneValue = form.getFieldValue("update_mobile");
    setShowPlaceholder(phoneValue.length < 2);
    setCountryData(countryData);
  };

  return (
    <div className="my-2 flex flex-col justify-center items-center px-6 py-4 w-full max-w-screen-2xl mx-auto h-[90vh]">
      <Form
        form={form}
        name="signup"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="my-6 pt-2 flex flex-col bg-custom-gradient w-full max-w-[470px] justify-center items-center rounded-xl mobile-m:rounded-3xl mobile-m:px-2"
      >
        <div className="flex flex-col justify-center items-center max-w-[380px] w-full">
          <div className="w-full flex justify-between items-center">
            <Button
              className="bg-transparent border-none text-[#A8BDC1] rounded-[42px] p-4 hover:bg-[#e8f3ff] hover:text-[#7e5aeb]"
              icon={<ArrowLeftOutlined />}
              onClick={() =>
                navigate("/verify-otp", {
                  state: {
                    email: location.state.formValues?.email,
                    password: location.state.formValues?.password,
                    phoneNumber: location.state.formValues?.phoneNumber,
                    formValues: location.state.formValues,
                  },
                })
              }
            />
            <Typography.Text className="w-full mr-8 font-urbanist text-center text-xl text-[#0F0F1C] font-semibold md:text-2xl">
              {"Enter your Mobile Number"}
            </Typography.Text>
          </div>

          <Row
            gutter={[12, 20]}
            className="my-3 w-full px-3 xs:px-2 sm:px-1 md:px-0 lg:!gap-y-5"
            wrap
            align={"middle"}
            justify={"center"}
          >
            <Col span={24}>
              <Col span={24} className="relative">
                <span
                  className={`absolute top-1/2 pl-[4.5rem] ${form.isFieldTouched("update_mobile") || isPhoneHasError ? "-translate-y-[75%] md:-translate-y-[55%] lg:-translate-y-[61%]" : "-translate-y-[50%]"}  text-[#aaa] pointer-events-none ${showPlaceholder ? "z-10" : "-z-10"} text-sm`}
                >
                  Mobile Phone Number*
                </span>
                <Form.Item
                  name="update_mobile"
                  rules={[
                    { required: true, message: "*Mobile Number is Required" },
                    phoneNumberLength(countryData),
                  ]}
                  validateFirst
                >
                  <PhoneInput
                    country={"us"}
                    disableDropdown
                    onlyCountries={["us"]}
                    countryCodeEditable={false}
                    onChange={validatePhone}
                    enableLongNumbers={
                      (countryData as CountryData)?.format.split(".").length -
                        1 <
                      15
                        ? false
                        : true
                    }
                  />
                </Form.Item>
              </Col>
            </Col>
          </Row>

          <Row className="w-full">
            <Col
              span={24}
              className="my-2 mb-6 px-4 flex justify-center items-center w-full md:px-2 lg:px-1"
            >
              <Button
                htmlType="submit"
                className="bg-[#7E5AE2] text-white border-none w-full font-semibold rounded-md text-sm"
                loading={isUpdatingPhoneNumber}
                iconPosition="end"
              >
                {"Confirm Mobile Number"}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default UpdateMobilePhone;
