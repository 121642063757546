import { Button, Checkbox, Col, Form, Row, Typography } from "antd";
import DocUpdateIcon from "../../assets/DocUpdateIcon.png";
import { ReactComponent as SignUpDocIcon } from "../../assets/signupdocIcon.svg";
import { useForm } from "../../utils/hooks/useForm";
import "react-phone-input-2/lib/style.css";
import FloatInput from "../../components/FloatInput/floatInput";
import "./signin.modules.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/hooks/useAuth"; // Import the useAuth hook
import {
  emailPattenValidation,
  passwordPatternValidation,
} from "../../utils/methods/form-validations";

const SignInV1 = () => {
  const navigate = useNavigate();
  const { form } = useForm();
  const { signin, isLoading } = useAuth(form); // Call the useAuth hook

  const onFinishFailed = (errorInfo: any) => {};

  const handleFinish = async (values: any) => {
    try {
      await signin(values); // Call onSubmit from the useAuth hook
    } catch (error) {
      onFinishFailed(error);
    }
  };

  return (
    <div className="my-2 flex flex-col justify-center items-center px-6 py-4 w-full max-w-screen-2xl mx-auto">
      <img
        src={DocUpdateIcon}
        alt="docupdate"
        className="w-full max-w-48 object-contain mix-blend-multiply lg:mr-auto lg:ml-12"
      />

      <div className="flex justify-center items-center w-full lg:justify-between lg:pr-4 lg:pl-8">
        <div className="hidden lg:flex lg:flex-col lg:gap-y-7 lg:justify-center lg:items-center lg:mb-auto lg:ml-2 lg:mt-12">
          <div className="flex flex-col gap-y-4 justify-center">
            <Typography.Text className="font-urbanist font-semibold text-3xl">
              Welcome to Docupdate
            </Typography.Text>
            <Typography.Text className="font-urbanist font-medium text-lg text-[#050505]">
              Don’t have an account?
              <span
                className="text-[#7E5AE2] cursor-pointer font-bold"
                onClick={() => navigate("/signup-new")}
              >
                {" "}
                Register Now
              </span>
            </Typography.Text>
            <div className="mt-6 ml-12 w-full">
              <SignUpDocIcon className="object-cover w-[75%] h-full" />
            </div>
          </div>
        </div>
        <Form
          form={form}
          name="signup"
          onFinish={handleFinish}
          onFinishFailed={onFinishFailed}
          className="my-6 pt-2 flex flex-col bg-custom-gradient w-full max-w-[470px] justify-center items-center rounded-xl mobile-m:rounded-3xl mobile-m:px-2"
        >
          <div className="flex flex-col justify-center items-center max-w-[380px]">
            <Typography.Text className="font-urbanist text-center text-3xl text-[#0F0F1C] font-semibold">
              Sign In
            </Typography.Text>
            <Typography.Text className="m-4 mb-0 font-urbanist text-center leading-4 text-[12px] text-normal text-[#5d6769] mobile-m:text-sm">
              Welcome to Docupdate.
            </Typography.Text>
            <Typography.Text className=" mb-4 font-urbanist text-center leading-4 text-[12px] text-normal text-[#5d6769] mobile-m:text-sm">
              Please login to your account
            </Typography.Text>

            {/* Tab container with absolute positioning */}
            <Row
              gutter={[12, 12]}
              className="px-5 xs:px-0 lg:!gap-y-5"
              wrap
              align={"middle"}
              justify={"center"}
            >
              {/* First Tab Fields */}
              <Col span={24}>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "*Email Address is Required" },
                    emailPattenValidation(),
                  ]}
                  validateFirst
                >
                  <FloatInput
                    name={"email"}
                    type="text"
                    placeholder={"Email Address *"}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "*Password is Required" },
                    passwordPatternValidation(),
                  ]}
                  validateFirst
                >
                  <FloatInput
                    name="password"
                    type="password"
                    placeholder={"Enter Password *"}
                  />
                </Form.Item>
              </Col>
              <Col
                span={24}
                className="mt-3 xs:mt-0 flex justify-between items-center max-h-custom"
              >
                <Typography.Text
                  className="text-xs text-[#6c838a] cursor-pointer"
                  onClick={() => navigate("/forgot-password")}
                >
                  Forget password?
                </Typography.Text>
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox className="mr-1 text-xs text-[#6c838a]">
                    Remember Me
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row className="w-full">
              <Col span={24} className="text-center mt-2 lg:hidden">
                <Typography.Text className="font-urbanist font-semibold text-sm text-[#050505]">
                  Don’t have an account?
                  <span
                    className="text-[#7E5AE2] cursor-pointer font-bold"
                    onClick={() => navigate("/signup-new")}
                  >
                    {" "}
                    Register Now
                  </span>
                </Typography.Text>
              </Col>
              <Col
                span={24}
                className="my-3 mb-6 px-4 flex justify-center items-center w-full md:px-2 lg:px-0 lg:mt-5"
              >
                <Button
                  htmlType="submit"
                  className="bg-[#7E5AE2] text-white border-none w-full font-semibold rounded-md text-sm"
                  loading={isLoading}
                  iconPosition="end"
                >
                  Sign In
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SignInV1;
